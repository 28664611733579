import axios from 'axios'
import { Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API + '/api', // url = base url + request url
  crossDomain: true, // 允许跨域
  // withCredentials: true, // 跨域请求时发送cookies
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // 在发送请求之前做一些事情
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
     * 如果要获取headers或status等http信息请返回response => response
     */

  /**
     * 通过自定义代码判断请求状态
     * 也可以通过 HTTP Status Code 来判断状态
     */
  response => {
    const res = response.data
    // 判断请求是否成功
    if (res.success) {
      return res
    } else {
      Message({
        message: res.msg || '服务器异常，请联系管理员！',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.msg || 'Error'))
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
