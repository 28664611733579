<template>
  <div class="home">
    <div class="main-content">
      <div class="logo">
        <img alt="logo" src="../assets/logo/CN-logo.svg">
        <div class="menu-area">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1"><a href="" target="_blank">首页</a></el-menu-item>
            <el-menu-item index="2" class="call-popover">
              <el-popover
                  placement="bottom"
                  trigger="hover">
                <div class="center-area call-popover">
                  <img src="../assets/icon/call.svg" alt="call"/>62709400
                </div>
                <a slot="reference">联系方式</a>
              </el-popover>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="other-info">
        <span class="txt">限时免费 下载即用</span>
        <div class="txt-info">
          <span>身在海外 一键加速回国</span>
          <span>畅玩国内游戏 看4K视频 听音乐 商务办公</span>
        </div>
        <div class="btn-list">
          <a v-if="versionUrl.androidDownloadUrl" class="download-btn" :href=versionUrl.androidDownloadUrl target="_blank">
            <img src="../assets/icon/Android.svg" alt=""/>Android 下载
          </a>
          <el-popover v-else
                      placement="top"
                      trigger="hover">
            <div class="center-area" style="text-align: center;">敬请期待~</div>
            <a slot="reference" class="download-btn">
              <img src="../assets/icon/Android.svg" alt=""/>Android 下载
            </a>
          </el-popover>

          <a v-if="versionUrl.iosDownloadUrl" class="download-btn" :href=versionUrl.iosDownloadUrl target="_blank">
            <img src="../assets/icon/IOS.svg" alt=""/>IOS 下载
          </a>
          <el-popover v-else
                      placement="top"
                      trigger="hover">
            <div class="center-area" style="text-align: center;">敬请期待~</div>
            <a slot="reference" class="download-btn">
              <img src="../assets/icon/IOS.svg" alt=""/>IOS 下载
            </a>
          </el-popover>

          <a v-if="versionUrl.windowsDownloadUrl" class="download-btn" :href=versionUrl.windowsDownloadUrl target="_blank">
            <img src="../assets/icon/windows.svg" alt=""/>Windows 下载
          </a>
          <el-popover v-else
              placement="bottom"
              trigger="hover">
            <div class="center-area" style="text-align: center;">敬请期待~</div>
            <a slot="reference" class="download-btn">
              <img src="../assets/icon/windows.svg" alt=""/>Windows 下载
            </a>
          </el-popover>

          <a v-if="versionUrl.macDownloadUrl" class="download-btn" :href=versionUrl.macDownloadUrl target="_blank">
            <img src="../assets/icon/mac.svg" alt=""/>MAC 下载
          </a>
          <el-popover v-else
                      placement="bottom"
                      trigger="hover">
            <div class="center-area" style="text-align: center;">敬请期待~</div>
            <a slot="reference" class="download-btn">
              <img src="../assets/icon/mac.svg" alt=""/>MAC 下载
            </a>
          </el-popover>
        </div>
      </div>
    </div>

    <div class="product-content">
      <div class="p-item">
        <div>
          <img src="https://static.testdaily.cn/gobackcn/image/01.png" alt="p-1">
        </div>
      </div>
      <div class="p-item">
        <div>
          <img src="https://static.testdaily.cn/gobackcn/image/02.png" alt="p-2">
        </div>
      </div>
     <div class="p-item">
       <div>
        <img src="https://static.testdaily.cn/gobackcn/image/03.png" alt="p-3">
       </div>
     </div>
     <div class="p-item">
       <div>
        <img src="https://static.testdaily.cn/gobackcn/image/04.png" alt="p-4">
       </div>
     </div>
    </div>

    <div class="footer-content">
      <div>
        <span class="txt-link">天津回易科技有限公司</span>|
        <a href="privacyPolicy.html" class="txt-link" target="_blank">隐私协议</a>|
        <a href="serviceAgreement.html" class="txt-link" target="_blank">服务协议</a>
      </div>
      <div>
        <a href="https://beian.miit.gov.cn" class="txt-link" target="_blank">津ICP备2022007844号</a>
      </div>
    </div>

  </div>

</template>

<script>


import {getVersionUrl} from "@/api/homeView";

export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
      activeIndex: '1',
      versionUrl: {
        androidDownloadUrl: "",
        iosDownloadUrl: "",
        macDownloadUrl: "",
        windowsDownloadUrl: "",
      },
    }
  },
  created() {
    this.getVersionUrl()
  },
  activated() {
    this.getVersionUrl()
  },
  methods: {
    getVersionUrl() {
      getVersionUrl().then(res => {
        this.versionUrl = res.data
      }).catch(() => {
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.home{
  width: 100%;
  min-width: 1200px;
  position: relative;
}
.main-content{
  width: 100%;
  padding: 80px;
  background: url("../assets/banner/banner.svg") no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  .menu-area{
    position: absolute;
    top: 40px;
    right: 40px;
    .el-menu{
      border: none;
      background: transparent;
      a{
        text-decoration: none;
      }
      .el-menu-item{
        height: auto;
        line-height: initial;
        margin-left: 15px;
        padding: 0 10px 8px;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #333;
      }
      .el-menu-item.is-active{
        color: #409EFF;
        border-bottom: 2px solid #409EFF;
      }
      .el-menu-item:focus, .el-menu-item:hover{
        color: #409EFF;
        border-bottom: 2px solid #409EFF!important;
        background: transparent;
      }
    }
  }
  .logo{
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    img{
      width: 210px;
    }
  }
  .other-info{
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .txt{
      margin: 0 0 40px;
      font-size: 60px;
      font-family: "Microsoft YaHei";
      color: #1f294d;
    }
    .txt-info{
      margin-bottom: 80px;
      line-height: 40px;
      font-size: 28px;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .btn-list{
      width: 450px;
      display: flex;
      flex-wrap: wrap;
      .download-btn{
        width: 190px;
        height: 56px;
        padding-left: 20px;
        margin-right: 10px;
        margin-bottom: 10px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        background: #000;
        border-radius: 4px;
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        img{
          width: 24px;
          margin-right: 10px;
        }
      }
    }
  }

}
.call-popover.center-area{
  color: #409EFF;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 20px;
    margin-right: 10px;
  }
}
.product-content{
  width: 100%;
  padding: 60px 0;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(auto-fill,calc(100% / 4));
  .p-item{
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      width: 80%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0 15px #4fa0ff;
      &:hover{
        border: 3px solid #4fa0ff;
      }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.footer-content{
  width: 100%;
  height: 120px;
  padding: 30px;
  background: #2e3037;
  color: #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div{
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .txt-link{
    padding: 0 15px;
    font-size: 14px;
    color: #afafaf;
    text-decoration: none;
    display: inline-block;
  }
}

@media only screen and (max-width:768px){
  .home{
    width: 100%;
    min-width: initial;
    position: relative;
  }
  .main-content{
    width: 100%;
    padding: 1rem;
    background: url("../assets/banner/banner.svg") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    .menu-area{
      position: absolute;
      top: 1rem;
      right: 1rem;
      .el-menu{
        border: none;
        background: transparent;
        a{
          text-decoration: none;
        }
        .el-menu-item{
          height: auto;
          line-height: initial;
          margin-left: 15px;
          padding: 0 10px 8px;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #333;
        }
        .el-menu-item.is-active{
          color: #409EFF;
          border-bottom: 2px solid #409EFF;
        }
        .el-menu-item:focus, .el-menu-item:hover{
          color: #409EFF;
          border-bottom: 2px solid #409EFF!important;
          background: transparent;
        }
      }
    }
    .logo{
      width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      img{
        width: 5rem;
      }
    }
    .other-info{
      width: 100%;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .txt{
        margin: 0 0 1rem;
        font-size: 1.2rem;
        color: #1f294d;
      }
      .txt-info{
        margin-bottom: 2rem;
        line-height: initial;
        font-size: 0.8rem;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .btn-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .download-btn{
          width: 150px;
          height: 45px;
          padding-left: 0.5rem;
          margin-right: 5px;
          margin-bottom: 5px;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          background: #333;
          border-radius: 4px;
          text-decoration: none;
          display: flex;
          align-items: center;
          cursor: pointer;
          img{
            width: 20px;
            margin-right: 8px;
          }
        }
      }
    }

  }
  .call-popover.center-area{
    color: #409EFF;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 20px;
      margin-right: 10px;
    }
  }
  .product-content{
    width: 100%;
    padding: 2rem 1rem;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(auto-fill,calc(100% / 2));
    .p-item{
      display: flex;
      align-items: center;
      justify-content: center;
      div{
        width: 90%;
        height: 95%;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 15px #4fa0ff;
        &:hover{
          border: 3px solid #4fa0ff;
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .footer-content{
    width: 100%;
    height: auto;
    padding: 1rem;
    background: #2e3037;
    color: #999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div{
      margin-bottom: 5px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .txt-link{
      padding: 0 5px;
      font-size: 12px;
      color: #afafaf;
      text-decoration: none;
      display: inline-block;
    }
  }
}

</style>
